import PropTypes from "prop-types";

const PriceCentsCalc = ({ currency = "$", priceCents = 0, toFixed = 2 }) =>
  `${currency}${(priceCents / 100).toFixed(`${toFixed}`)}`;

PriceCentsCalc.defaultProps = {
  priceCents: 0,
  toFixed: 0,
};

PriceCentsCalc.propTypes = {
  currency: PropTypes.string,
  toFixed: PropTypes.number,
  priceCents: PropTypes.number,
};

export default PriceCentsCalc;
