import PropTypes from "prop-types";
import { grey } from "@material-ui/core/colors";
import {
  StickyFooterWrapper,
  TitleWrapper,
  StickyFooterInfoWrapper,
  StickyFooterAmount,
} from "./styles";
import PriceCentsCalc from "../PriceCentsCalc";

const StickyFooter = ({
  title,
  bgColor,
  priceCents,
  handleOnclick,
  totalOrderCount,
  leftContent,
  disabled,
}) => {
  const buttonColor = disabled ? grey[600] : bgColor;

  return (
    <StickyFooterWrapper
      bgcolor={buttonColor}
      onClick={!disabled ? handleOnclick : undefined}
    >
      <StickyFooterInfoWrapper>
        {totalOrderCount && (
          <StickyFooterAmount>{totalOrderCount}</StickyFooterAmount>
        )}
        {leftContent}
      </StickyFooterInfoWrapper>

      <TitleWrapper>
        <span>{title}</span>
      </TitleWrapper>

      <StickyFooterInfoWrapper right>
        {priceCents > 0 && (
          <PriceCentsCalc currency="$" priceCents={priceCents} toFixed={2} />
        )}
        {priceCents === 0 && <span>FREE</span>}
      </StickyFooterInfoWrapper>
    </StickyFooterWrapper>
  );
};

StickyFooter.defaultProps = {
  totalOrderCount: null,
  priceCents: null,
  bgColor: null,
  disabled: false,
};

StickyFooter.propTypes = {
  title: PropTypes.string.isRequired,
  handleOnclick: PropTypes.func.isRequired,
  bgColor: PropTypes.string,
  totalOrderCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  priceCents: PropTypes.number,
  disabled: PropTypes.bool,
};

export default StickyFooter;
