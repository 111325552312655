import { withStyles } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  text: {
    display: "flex",
    fontSize: "0.8em",
    fontWeight: 300,
    paddingRight: "3rem",
    lineHeight: 1.4,
    color: grey[600],
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const Dialog = withStyles((theme) => ({
  paper: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      maxHeight: "100%",
      overflowY: "auto",
      margin: 0,
    },
  },
}))(MuiDialog);

export const DialogActions = withStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "stretch",
    padding: 0,
    backgroundColor: grey[100],

    "& > button": {
      width: "100%",
    },
  },
}))(MuiDialogActions);

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="p" className={classes.text}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
