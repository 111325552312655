import { useCallback, useMemo } from "react";
import {
  HiLink,
  HiOutlineCalendar,
  HiOutlineClock,
  HiOutlineShoppingBag,
} from "react-icons/hi";
import { RiContactsLine } from "react-icons/ri";
import { useRouter } from "next/router";

const useLayoutData = ({ account }) => {
  const router = useRouter();
  const { asPath } = router;

  const {
    microsite: { eventsPage, formsPage, linksPage, productsPage, schedulePage },
  } = account;

  const checkIsActive = useCallback(
    (path) => {
      const pagePath = path?.toLowerCase() || "";
      const currentPath = `${asPath.split("?")[0]}/`.toLowerCase();
      return pagePath === currentPath;
    },
    [asPath]
  );

  // change this when icon added from backend
  const menuItems = [
    {
      position: `${linksPage?.menuSettings?.position}`,
      icon: <HiLink />,
      label: `${linksPage?.menuSettings?.title || ""}`,
      URL: `${linksPage?.urlPath}`,
      active: linksPage !== null,
      isActive: checkIsActive(linksPage?.urlPath),
    },
    {
      position: `${formsPage?.menuSettings?.position}`,
      icon: <RiContactsLine />,
      label: `${formsPage?.menuSettings?.title || ""}`,
      URL: `${formsPage?.urlPath}`,
      active: formsPage !== null,
      isActive: checkIsActive(formsPage?.urlPath),
    },
    {
      position: `${schedulePage?.menuSettings?.position}`,
      icon: <HiOutlineClock />,
      label: `${schedulePage?.menuSettings?.title || ""}`,
      URL: `${schedulePage?.urlPath}`,
      active: schedulePage !== null,
      isActive: checkIsActive(schedulePage?.urlPath),
    },
    {
      position: `${eventsPage?.menuSettings?.position}`,
      icon: <HiOutlineCalendar />,
      label: `${eventsPage?.menuSettings?.title || ""}`,
      URL: `${eventsPage?.urlPath}`,
      active: eventsPage !== null,
      isActive: checkIsActive(eventsPage?.urlPath),
    },
    {
      position: `${productsPage?.menuSettings?.position}`,
      icon: <HiOutlineShoppingBag />,
      label: `${productsPage?.menuSettings?.title || ""}`,
      URL: `${productsPage?.urlPath}`,
      active: productsPage !== null,
      isActive: checkIsActive(productsPage?.urlPath),
    },
  ];

  return useMemo(
    () => ({
      menuItems,
    }),
    [menuItems]
  );
};

export default useLayoutData;
