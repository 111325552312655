export const CART_CATEGORY = {
  tickets: "tickets",
  products: "products",
  digitalProducts: "digitalProducts",
};

export const CART_CATEGORY_LABEL = {
  tickets: "tickets",
  products: "shop",
  digitalProducts: "digital products",
};

export const DELIVERY_OPTIONS = [
  { label: "Ship It to Me", value: "SHIPPING" },
  { label: "Pick Up Now", value: "PICKUP" },
];

export const DELIVERY_OPTIONS_LABEL = {
  SHIP: "SHIPPING",
  PICK: "PICKUP",
};
