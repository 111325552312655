import { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { useRouter } from "next/router";
import UserInfoHeader from "../../layouts/UserInfoHeader";
import {
  CartItemInfo,
  CartItemPrice,
  CartItemWrapper,
  CartList,
  CartSection,
  CartTitle,
  CartWrapper,
  CloseAction,
  MenuContainer,
  SlidingMenuWrapper,
  Title,
  TitleContainer,
} from "./styles";
import { AppContext } from "../../site-pages/context";
import CartItem from "./CartItem";
import StickyFooter from "../StickyFooter";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import {
  CART_CATEGORY,
  CART_CATEGORY_LABEL,
} from "../../site-pages/Checkout/context/contstants";
import { PRODUCT_TYPES } from "../../constants/products";

// cart = {
//   category: [
//     {
//       item: {},
//       amount: 0,
//     },
//   ],
// };

const DrawerCart = ({
  state,
  firstName,
  lastName,
  middleNames,
  description,
  avatarImg,
  toggleMenu,
  account,
}) => {
  const {
    cart = {},
    removeItemFromCart,
    getSubtotal,
    getTotalPrice,
    shippingPrice,
    toggleCart,
  } = useContext(AppContext);

  const [modalData, setModalData] = useState({
    showActionBtns: true,
    title: "Confirm",
    confirmBtnCopy: "Delete",
    open: false,
    handleCancel: () => setModalData((data) => ({ ...data, open: false })),
    handleConfirm: () => {},
    isValid: true,
  });

  const router = useRouter();
  const { userHandle } = router.query;

  const goToCheckout = useCallback(() => {
    router.push(`/${userHandle}/checkout`);
    toggleCart();
  }, [router, toggleCart, userHandle]);

  const handleRemoveCartItem = useCallback(
    (category, itemId) => {
      setModalData({
        ...modalData,
        handleConfirm: () => {
          removeItemFromCart(category, itemId);
          setModalData((data) => ({ ...data, open: false }));
        },
        open: true,
      });
    },
    [modalData, removeItemFromCart]
  );

  const getSubtotalPrice = useCallback(
    (items) => {
      const totalPrice = getSubtotal(items);
      return totalPrice ? `$${Number(totalPrice).toFixed(2)}` : "FREE";
    },
    [getSubtotal]
  );

  const getShippingPriceText = useCallback(() => {
    const totalPrice = +shippingPrice / 100;
    return totalPrice ? `$${Number(totalPrice).toFixed(2)}` : "FREE";
  }, [shippingPrice]);

  const getTotalPriceText = useCallback(() => {
    const totalPrice = getTotalPrice();
    return totalPrice ? `$${Number(totalPrice).toFixed(2)}` : "FREE";
  }, [getTotalPrice]);

  const hasNonDigitalProducts =
    cart.products?.findIndex(
      ({ item }) => item.type !== PRODUCT_TYPES.DIGITAL
    ) !== -1;

  return (
    <SlidingMenuWrapper state={state}>
      <MenuContainer state={state}>
        <TitleContainer onClick={toggleMenu}>
          <Title>CART</Title>
          <CloseAction>
            <CloseIcon fontSize="inherit" />
          </CloseAction>
        </TitleContainer>

        <UserInfoHeader
          middleNames={middleNames}
          avatarImg={avatarImg}
          firstName={firstName}
          lastName={lastName}
          description={description}
          isVerified
          progress={100}
          account={account}
        />

        <CartWrapper>
          {Object.keys(cart).map((key) => (
            <CartSection key={key}>
              <CartTitle>{CART_CATEGORY_LABEL[key]}</CartTitle>
              <CartList>
                {cart[key].map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    onRemove={(id) => handleRemoveCartItem(key, id)}
                  />
                ))}
              </CartList>
              <CartItemWrapper title>
                <CartItemInfo>Subtotal</CartItemInfo>
                <CartItemPrice>{getSubtotalPrice(cart[key])}</CartItemPrice>
              </CartItemWrapper>
              {CART_CATEGORY.products === key && hasNonDigitalProducts && (
                <>
                  <CartItemWrapper title>
                    <CartItemInfo>Shipping</CartItemInfo>
                    <CartItemPrice>{getShippingPriceText()}</CartItemPrice>
                  </CartItemWrapper>
                  <CartItemWrapper title>
                    <CartItemInfo>Total</CartItemInfo>
                    <CartItemPrice>{getTotalPriceText()}</CartItemPrice>
                  </CartItemWrapper>
                </>
              )}
            </CartSection>
          ))}

          {Object.keys(cart).length === 0 && <p> Your cart is empty </p>}
        </CartWrapper>

        <ConfirmationDialog {...modalData}>
          Remove this item?
        </ConfirmationDialog>

        {Object.keys(cart).length > 0 && (
          <StickyFooter title="Checkout" handleOnclick={goToCheckout} />
        )}
      </MenuContainer>
    </SlidingMenuWrapper>
  );
};

DrawerCart.propTypes = {
  state: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleNames: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  avatarImg: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired,
  account: PropTypes.shape(),
};

DrawerCart.defaultProps = {
  firstName: "",
  lastName: "",
  description: "",
  avatarImg: "",
  middleNames: [],
  account: {},
};

export default DrawerCart;
