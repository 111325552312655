import Link from "next/link";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import UserInfoHeader from "../../layouts/UserInfoHeader";

import {
  MenuContainer,
  SlidingMenuWrapper,
  TitleContainer,
  Title,
  CloseAction,
  LinkItemWrapper,
  LinkItem,
  LinkIcon,
  LinkLabel,
  LinkSubtitle,
} from "./styles";
import useLayoutData from "../../hooks/useLayoutData";

const DrawerMenu = ({
  state,
  firstName,
  lastName,
  middleNames,
  description,
  avatarImg,
  toggleMenu,
  account,
}) => {
  const { menuItems } = useLayoutData({ account });
  const handleMenuItemClick = () => toggleMenu();
  const renderLinkItem = (m) => (
    <Link href={`${m.URL}`} key={m.position}>
      <LinkItemWrapper
        isDarkMode
        key={m.position}
        onClick={() => handleMenuItemClick(m)}
      >
        <LinkItem isDarkMode>
          <LinkIcon>{m.icon}</LinkIcon>
          <LinkLabel>{m.label}</LinkLabel>
        </LinkItem>
        <LinkSubtitle>{m.subtitle}</LinkSubtitle>
      </LinkItemWrapper>
    </Link>
  );

  return (
    <SlidingMenuWrapper state={state}>
      <MenuContainer state={state}>
        <TitleContainer onClick={() => toggleMenu()}>
          <Title>MENU</Title>
          <CloseAction>
            <CloseIcon fontSize="inherit" />
          </CloseAction>
        </TitleContainer>

        <UserInfoHeader
          middleNames={middleNames}
          avatarImg={avatarImg}
          firstName={firstName}
          lastName={lastName}
          description={description}
          isVerified
          progress={100}
          account={account}
        />

        {menuItems.filter((m) => !!m && m.active).map((m) => renderLinkItem(m))}
      </MenuContainer>
    </SlidingMenuWrapper>
  );
};

DrawerMenu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  description: PropTypes.string,
  avatarImg: PropTypes.string,
  state: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  account: PropTypes.shape().isRequired,
  middleNames: PropTypes.arrayOf(PropTypes.string),
};

DrawerMenu.defaultProps = {
  firstName: "",
  lastName: "",
  description: "",
  avatarImg: "",
  middleNames: [],
};

export default DrawerMenu;
