import styled, { css } from "styled-components";

const footerBtn = (props) => css`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 55px;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1rem;
  position: sticky;
  bottom: 0;
  z-index: 999;

  user-select: none;

  background-color: ${props.bgcolor || "var(--brand-color)"};
  color: white;

  &:active {
    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;

export const StickyFooterWrapper = styled.div`
  ${(props) => footerBtn(props)};
  margin-top: auto;
`;

export const StickyFooterLinkWrapper = styled.a`
  ${(props) => footerBtn(props)};
  text-decoration: none;
`;

export const StickyFooterInfoWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 2 1 auto;
  align-items: center;
  justify-content: flex-start;

  font-size: 0.9em;
  font-weight: 800;
  width: 0;

  > span {
    font-size: 1.2em;
    font-weight: 400;
  }

  ${(props) =>
    props.right &&
    css`
      justify-content: flex-end;
    `}
`;

export const StickyFooterAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid white;
  border-radius: 500rem;
  height: 2em;
  padding: 0.2em;
  width: 2em;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.9em;
`;
