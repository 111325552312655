import PropTypes from "prop-types";
import { DeleteOutline } from "@material-ui/icons";
import {
  CartItemAction,
  CartItemInfo,
  CartItemPrice,
  CartItemWrapper,
} from "./styles";
import { PRODUCT_TYPES } from "../../constants/products";

const stringifyOptions = (obj = {}) => {
  const result = [];
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      result.push(`${key}: ${value}`);
    }
  }
  return result.join(", ");
};

const CartItem = ({ item: { item, amount }, onRemove }) => (
  <CartItemWrapper>
    <CartItemInfo>
      {`${item?.type === PRODUCT_TYPES.DIGITAL ? 1 : amount} x ${
        item.name
      } ${stringifyOptions(item.options)}`}
    </CartItemInfo>
    <CartItemPrice>
      {item.priceCents ? `$${(item.priceCents / 100).toFixed(2)}` : "FREE"}
    </CartItemPrice>
    <CartItemAction onClick={() => onRemove(item.id)}>
      <DeleteOutline />
    </CartItemAction>
  </CartItemWrapper>
);

CartItem.defaultProps = {
  onRemove: () => true,
};

CartItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onRemove: PropTypes.func,
};

export default CartItem;
