import styled, { css } from "styled-components";
import { grey } from "@material-ui/core/colors";

export const SlidingMenuWrapper = styled.div`
  position: absolute;
  z-index: 999;
  transition: 0.2s;
  height: 100vh;
  width: 100%;
  background: rgba(var(--background-color-rgb), 0);
  box-sizing: border-box;
  overflow-x: hidden;
  pointer-events: none;
  ${({ state }) =>
    state &&
    css`
      background: rgba(var(--background-color-rgb), 0.3);
      pointer-events: all;
    `}
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  width: 100%;
  height: 100vh;
  padding: 0.5rem;
  color: var(--text-color);
  background: var(--background-color);
  box-sizing: border-box;
  transform: translateX(200%);
  transition: transform 0.2s ease;
  ${({ state }) =>
    state &&
    css`
      transform: translateX(0);
    `};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  border-bottom: 1px solid ${grey[800]};
  padding: 0.5rem;
  margin: 0 0.5rem 0.9rem 0.5rem;
  max-width: 500px;
`;
export const CloseAction = styled.div`
  display: flex;
  color: var(--text-color);
  margin-left: auto;
  font-size: 1.9em;
  cursor: pointer;
`;
export const Title = styled.h6`
  font-size: 1.1em;
  padding: 0;
  margin: 0;
`;

export const CartWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0.5em;
  color: ${grey[300]};
`;

export const CartSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
`;

export const CartTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--text-color);
  margin: 0 0 0.5rem;
`;

export const CartList = styled.div``;

export const CartItemWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem 0.25rem;

  ${(props) =>
    props.title &&
    css`
      padding: 0.75rem 0.25rem;
      font-size: 1.2rem;
      font-weight: 600;
    `}
`;

export const CartItemInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
`;

export const CartItemPrice = styled.div`
  flex: 0 0 auto;
  margin: 0 1rem;
`;

export const CartItemAction = styled.div`
  flex: 0 0 auto;
`;
