import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { Dialog, DialogTitle } from "./styles";

// eslint-disable-next-line react/display-name
const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

const ConfirmationDialog = ({
  open,
  title,
  maxWidth,
  cancelBtnCopy,
  confirmBtnCopy,
  handleCancel,
  handleConfirm,
  showActionBtns,
  children,
  isValid,
}) => (
  <Dialog
    fullWidth
    maxWidth={maxWidth}
    open={open}
    onClose={handleCancel}
    aria-labelledby="Confirmation-title"
    TransitionComponent={TransitionUp}
  >
    <DialogTitle id="Confirmation-title" onClose={handleCancel}>
      {title}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    {showActionBtns && (
      <DialogActions>
        <Button onClick={handleCancel}>{cancelBtnCopy}</Button>
        <Button disabled={!isValid} color="primary" onClick={handleConfirm}>
          {confirmBtnCopy}
        </Button>
      </DialogActions>
    )}
  </Dialog>
);

ConfirmationDialog.defaultProps = {
  open: false,
  showActionBtns: false,
  title: "Please confirm",
  maxWidth: "sm",
  cancelBtnCopy: "Cancel",
  confirmBtnCopy: "Confirm",
  handleConfirm: () => false,
  isValid: false,
};

ConfirmationDialog.propTypes = {
  isValid: PropTypes.bool,
  open: PropTypes.bool,
  showActionBtns: PropTypes.bool,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  cancelBtnCopy: PropTypes.string,
  confirmBtnCopy: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ConfirmationDialog;
