import styled, { css } from "styled-components";
import { grey } from "@material-ui/core/colors";

export const SlidingMenuWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  transition: 0.2s;
  height: 100vh;
  width: 100%;
  background: rgba(var(--background-color-rgb), 0);
  box-sizing: border-box;
  overflow-x: hidden;
  pointer-events: none;
  ${({ state }) =>
    state &&
    css`
      background: rgba(var(--background-color-rgb), 0.3);
      pointer-events: all;
    `}
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0.5rem;
  color: var(--text-color);
  background: var(--background-color);
  box-sizing: border-box;
  transform: translateX(-100%);
  transition: transform 0.2s ease;
  ${({ state }) =>
    state &&
    css`
      transform: translateX(0);
    `};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  border-bottom: 1px solid ${grey[800]};
  padding: 0.5rem;
  margin: 0 0.5rem 0.9rem 0.5rem;
  max-width: 500px;
`;
export const CloseAction = styled.div`
  display: flex;
  color: var(--text-color);
  margin-left: auto;
  font-size: 1.9em;
  cursor: pointer;
`;
export const Title = styled.h6`
  font-size: 1.1em;
  padding: 0;
  margin: 0;
`;

export const LinkItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0.5em;
  color: ${grey[300]};
  margin: 0.5rem;
  // background:${grey[900]};
  border-radius: 0.3em;
`;

export const LinkItem = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

export const LinkIcon = styled.div`
  display: flex;
  margin-right: 0.5rem;
  font-size: 1.7em;
  color: var(--text-color);
`;

export const LinkLabel = styled.div`
  display: flex;
  font-size: 1.2em;
  color: var(--text-color);
`;

export const LinkArrow = styled.div`
  display: flex;
  margin-left: auto;
  color: var(--text-color);
`;

export const LinkSubtitle = styled.div`
  // display: flex;
  width: 100%;
  color: ${grey[600]};
  font-size: 0.8em;
  margin-top: 0.3rem;
  display: none;
`;

export const MenuDividerTitle = styled.div`
  text-transform: uppercase;
  margin: 1em 0 0 0;
  font-size: 0.8em;
  color: ${grey[600]};
`;
